import styled from "styled-components";
import { fontMedium14, fontNormal14 } from "../../utils/styleConsts";

export const Summary = styled.div<{
  desktop: boolean;
  openCarosal: boolean;
}>`
  transition: 0.3s;
  max-height: ${(p) =>
    p.desktop ? "100rem" : p.openCarosal ? "100rem" : "5rem"};
  overflow: ${(p) =>
    p.desktop ? "visible" : p.openCarosal ? "auto" : "hidden"};
  @media (max-width: 769px) {
    overflow: ${(p) => (p.openCarosal ? "auto" : "hidden")};
  }
  .headers {
    display: grid;
    grid-template-columns: auto 2rem;
    justify-content: space-between;
    .heading {
      font-size: 1.6rem;
      color: var(--NEUTRAL-4);
      font-family: "GothamMedium";
      font-weight: 350;
      font-style: normal;
    }
    .carosal-btn {
      height: 2.5rem;
      line-height: 0.5rem;
      position: relative;
      cursor: pointer;
      width: 1rem;
      .icon {
        width: 1rem;
        height: auto;
        display: ${(p) => (!p.desktop ? "block" : "none")};
        transition: 0.3s;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
          ${(p) => (!p.openCarosal ? "rotate(180deg)" : "rotate(0deg)")};
        svg {
          path {
            fill: var(--NEUTRAL-4);
          }
        }
      }
    }
  }

  h4 {
    font-size: 1.6rem;
  }
  .info {
    margin-right: 2rem;
    padding-top: 1rem;
    &.left-align .details {
      grid-template-columns: 16rem auto;
      .value {
        text-align: start;
      }
    }
    @media (max-width: 769px) {
      margin-right: 0;
    }
    @media (max-width: 1000px) {
      margin-right: 1rem;
    }
    .details,
    .additonal_details {
      display: grid;
      color: var(--NEUTRAL-4);
      margin: 1.6rem 0;
      .label {
        color: var(--NEUTRAL-3);
        font-family: "GothamMedium";
      }
    }
    .details {
      grid-template-columns: 15rem auto;
      .value {
        text-align: end;
        b {
          font-weight: 350;
          font-family: "GothamMedium";
        }
      }

      .value {
        display: grid;
        overflow-wrap: anywhere;
        @media (max-width: 840px) {
          text-align: right;
        }
      }
      .price_change_text {
        font-size: 1.2rem;
        font-family: Gotham;
        font-weight: 325;
        color: var(--NEUTRAL-3);
        margin-top: 0.8rem;
        span {
          &:first-child {
            display: inline-block;
            margin-right: 1rem;
          }
        }
      }
    }
    .additonal_details {
      grid-template-columns: 30% auto;
      .images {
        display: flex;
        gap: 1rem;
        img {
          border-radius: 1rem;
          width: 7rem;
          height: 7rem;
        }
      }
    }
  }
  .action-needed-green-banner {
    background-color: rgba(40, 94, 95, 0.3);
    margin-top: 1rem;
    margin-right: 1rem;
    color: var(--PRIMARY);
    padding: 0.6rem 1rem;
    border-radius: 1rem;
    margin-left: -1rem;
    @media (max-width: 769px) {
      margin-right: 0rem;
      margin-left: 0;
    }
  }
`;

export const Price = styled.span`
  display: grid;
  text-align: end;
  b {
    font-weight: 350;
    font-family: "GothamMedium";
  }
  .price_change_text {
    font-size: 1.2rem;
    color: var(--NEUTRAL-3);
    margin-top: 0.8rem;
  }
`;

export const TextBold = styled.span`
  font-family: "GothamMedium";
  color: var(--NEUTRAL-4);
  font-size: 1.6rem;
  .value {
    text-align: end;
  }
`;

export const LoadTermsBoxWrapper = styled.div`
  border: 0.1rem solid #62707833;
  border-radius: 1rem;
  padding: 1.2rem;
  display: grid;
  grid-template-columns: auto 6.8rem;
  gap: 1rem;
  align-items: center;
  .text-content {
    .label {
      ${fontMedium14}
      color:var(--NEUTRAL-3);
      margin-bottom: 0.8rem;
    }
    .value {
      ${fontNormal14}
      color:var(--NEUTRAL-4);
    }
    .value {
      display: grid;
      overflow-wrap: anywhere;
      @media (max-width: 840px) {
        text-align: right;
      }
    }
    .price_change_text {
      font-size: 1.2rem;
      font-family: Gotham;
      font-weight: 325;
      color: var(--NEUTRAL-3);
      margin-top: 0.8rem;
      span {
        &:first-child {
          display: inline-block;
          margin-right: 1rem;
        }
      }
    }
  }
  .img-holder {
    max-width: 6.8rem;
    max-height: 6.8rem;
    min-width: 6.8rem;
    min-height: 6.8rem;
    width: 100%;
    height: 100%;
    background-color: var(--NEUTRAL-1);
    display: grid;
    place-items: center;
    border-radius: 1rem;
    overflow: hidden;
    img {
      cursor: pointer;
      max-width: 6rem;
      max-height: 6rem;
    }
  }
`;

export const DetailsSummarySeperationBorderWrapper = styled.div`
  height: 0.1rem;
  width: 100%;
  background-color: var(--NEUTRAL-2);
  border: none;
  margin: 2rem 0;
`;
