import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GET } from "../../services/api";
import {
  IOrderDetails,
  IOrderFilterSelected,
  IOrderReducer,
} from "../../interfaces/OrdersInterface";

export const order_details: IOrderDetails = {
  additional_info: null,
  city: "",
  created_at: "",
  dialog_id: "",
  grade: {
    category_id: 0,
    category_name: "",
    id: 0,
    name: "",
    unit: "",
    image: null,
    sub_category_1: null,
    sub_category_2: null,
    price_type: "",
  },
  grade_id: 0,
  grade_images: null,
  id: 0,
  is_draft: false,
  load_info: "",
  loads: 0,
  loads_left: 0,
  min_weight: 0,
  offer_id: null,
  load_distribution: [],
  terminal_id: 0,
  shipping_location: {
    address: "",
    address_2: null,
    address_3: null,
    city: "",
    shipping_schedule: [],
    state: "",
    zip_code: 0,
    preferred_location_id: 0,
    port_id: 0,
    destination_id: 0,
    terminal_id: 0,
    state_id: 0,
    name: "",
    state_code: "",
  },
  shipping_location_id: 0,
  state_code: 0,
  status: "",
  supplier: null,
  supplier_id: "",
  total_price: 0,
  trader: null,
  trader_id: "",
  unit_price: 0,
  updated_at: "",
  sell_type: "",
  freight_type: "FAS",
  supplier_weight_unit: "lb",
  sale_flow_type: "WEIGHT_PER_LOAD",
  material_packing_type: "",
  grade_subcategory_1_info: {
    id: 0,
    name: "string",
  },
};
const meta = {
  count: 0,
  page: 0,
  size: 0,
  total_count: 0,
  total_pages: 0,
};
const initialState: IOrderReducer = {
  order_current_page: 1,
  search: "",
  order_table_loader: false,
  order_details_loader: false,
  order_list: {
    data: [],
    meta: meta,
  },
  selected_order_details: order_details,
  gradesList: [],
  locationList: [],
  orderFilterQuery: "",
  orderFilter: {
    locationdropdown: undefined,
    gradelistDropdown: undefined,
    statusDropDown: undefined,
  },
  sellAgainModalSt: false,
  sortOptions: {
    sort: undefined,
    order: undefined,
  },
  orderFilterCount: 0,
  emptyText:
    "Once you submit an sale, you can track the status here. Click on Deals to find your material and lock in your price!",
  isFilterSet: false,
  isAppliedClicked: false,
  order_status_options: [],
};

export const orderSlice = createSlice({
  name: "order_reducer",
  initialState,
  reducers: {
    updateOrderPagination: (state, action: PayloadAction<number>) => {
      state.order_current_page = action.payload;
    },
    updateOrderSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateOrderFilterQuery: (state, action: PayloadAction<string>) => {
      state.orderFilterQuery = action.payload;
    },
    updateOrderFilterSelection: (
      state,
      action: PayloadAction<IOrderFilterSelected>
    ) => {
      state.orderFilter = action.payload;
    },
    setsellAgainModalSt: (state, action: PayloadAction<boolean>) => {
      state.sellAgainModalSt = action.payload;
    },
    setSortOptions: (
      state,
      action: PayloadAction<{ sort: string; order: string }>
    ) => {
      state.sortOptions = action.payload;
    },
    setOrderFilterCount: (state, action: PayloadAction<number>) => {
      state.orderFilterCount = action.payload;
    },
    setEmptyText: (state, action: PayloadAction<string>) => {
      state.emptyText = action.payload;
    },
    setIsFilterSet: (state, action: PayloadAction<boolean>) => {
      state.isFilterSet = action.payload;
    },
    setIsAppliedClicked: (state, action: PayloadAction<boolean>) => {
      state.isAppliedClicked = action.payload;
    },
    resetOrderDetailsData: (state) => {
      state.selected_order_details = order_details;
    },
    resetOrderReducer: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(GET("order/listing", "").pending, (state) => {
      state.order_table_loader = true;
    });
    builder.addCase(GET("order/listing", "").fulfilled, (state, action) => {
      state.order_table_loader = false;
      state.order_list = action.payload;
      if (action.payload?.data?.length <= 0) {
        state.emptyText =
          " Once you submit an sale, you can track the status here. Click on Deals to find your material and lock in your price!";
      }
    });
    builder.addCase(GET("order/listing", "").rejected, (state) => {
      state.order_table_loader = false;
      state.order_list.data = [];
      state.emptyText =
        " Once you submit an sale, you can track the status here. Click on Deals to find your material and lock in your price!";
    });
    builder.addCase(GET("order/details", "").pending, (state) => {
      state.selected_order_details = order_details;
      state.order_details_loader = true;
    });
    builder.addCase(GET("order/details", "").fulfilled, (state, action) => {
      state.selected_order_details = action.payload.data;
      state.order_details_loader = false;
    });
    builder.addCase(GET("order/details", "").rejected, (state) => {
      state.selected_order_details = order_details;
      state.order_details_loader = false;
    });
    builder.addCase(GET("ordergradesList", "").pending, (state, action) => {});
    builder.addCase(GET("ordergradesList", "").fulfilled, (state, action) => {
      state.gradesList = action.payload.data;
    });
    builder.addCase(GET("ordergradesList", "").rejected, (state) => {
      state.gradesList = [];
    });
    builder.addCase(
      GET("orderlocationlist", "").pending,
      (state, action) => {}
    );
    builder.addCase(GET("orderlocationlist", "").fulfilled, (state, action) => {
      state.locationList = action.payload.data.map((el: string) => {
        return { location_id: el, location_name: el };
      });
    });
    builder.addCase(GET("orderlocationlist", "").rejected, (state) => {
      state.locationList = [];
    });
    builder.addCase(GET("orderstatusFilter", "").fulfilled, (state, action) => {
      state.order_status_options = action.payload.data;
    });
    builder.addCase(GET("orderstatusFilter", "").rejected, (state) => {
      state.order_status_options = [];
    });
  },
});

export default orderSlice.reducer;
export const {
  updateOrderPagination,
  updateOrderSearch,
  updateOrderFilterQuery,
  updateOrderFilterSelection,
  setsellAgainModalSt,
  setSortOptions,
  setOrderFilterCount,
  setEmptyText,
  setIsFilterSet,
  setIsAppliedClicked,
  resetOrderDetailsData,
  resetOrderReducer,
} = orderSlice.actions;
