import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChatInterface,
  MessageDialogListInterface,
  MessageReducerInterface,
  UserDetailsInterface,
  UserListInterface,
} from "../../interfaces/MessagesInterface";
import { GET } from "../../services/api";
import {
  chatbot_exploreMessage,
  chatbot_helpfulMessage,
  chatbot_relatedMessage,
} from "../../components/ChatBox/ChatBoxConst";
import { chatbot_dailog_filter } from "../../utils/constant";
import QB_sendSyncGroupImg from "../../utils/QuickBlox/QB_sendSyncGroupImg";

const Userdetails = {
  id: 0,
  full_name: "",
  email: "",
  user_tags: "",
};

const initialActiveChatState = {
  _id: "",
  created_at: "",
  last_message: "",
  last_message_date_sent: 0,
  last_message_id: "",
  last_message_user_id: 0,
  name: "",
  occupants_ids: [],
  photo: null,
  type: 0,
  updated_at: "",
  user_id: 0,
  xmpp_room_jid: null,
  unread_messages_count: 0,
  limit: 100,
  skip: 0,
  noMore: false,
};

let global_admin_details: any = localStorage.getItem("global_admin_details");
let backend_details: any = localStorage.getItem("backend_details");
let user_list: any = localStorage.getItem("user_list");
let user_id_n_name: any = localStorage.getItem("user_id_n_name");

if (global_admin_details !== null) {
  global_admin_details = JSON.parse(global_admin_details);
}
if (backend_details !== null) {
  backend_details = JSON.parse(backend_details);
}

if (user_list !== null) {
  user_list = JSON.parse(user_list);
}

if (user_id_n_name !== null) {
  user_id_n_name = JSON.parse(user_id_n_name);
}

const initialState: MessageReducerInterface = {
  qb_initiated: false,
  message_loader: true,
  reconnecting_message: "",
  view_responsive_chats: false,
  hide_helpful_msg: false,
  dialog_list: {},
  unread_count: {
    dialog_direct_unread: 0,
    dialog_other_unread: 0,
    dialog_unread: 0,
  },
  user_id: 0,
  session_id: "",
  new_message_page: true,
  loading_more_chats: false,
  go_end: false,
  active_dialog: {
    _id: "",
    created_at: "",
    last_message: "",
    last_message_date_sent: 0,
    last_message_id: "",
    last_message_user_id: 0,
    name: "",
    occupants_ids: [],
    photo: null,
    type: 0,
    updated_at: "",
    user_id: 0,
    xmpp_room_jid: null,
    unread_messages_count: 0,
    limit: 100,
    skip: 0,
    noMore: false,
  },
  active_dialog_id: "",
  user: {
    email: "",
    email_verified: false,
    given_name: "",
    family_name: "",
    sub: "",
    "custom:user_role": "",
    phone_number: "",
  },
  user_id_n_name: user_id_n_name ? user_id_n_name : {},
  global_admin_details: global_admin_details
    ? global_admin_details
    : Userdetails,
  backend_details: backend_details ? backend_details : Userdetails,
  user_list: user_list ? user_list : [],
  user_name: "",
  chat_history: {},
  online_users: [],
  pre_populated_users: [],
  new_message_modal_loader: false,
  show_new_message_modal: false,
  last_sent_message_time: "",
  new_message_creation_failed: false,
  chat_upload: {},
  transaction_chat: false,
  attachment_active_video_index: 0,
  dialog_list_obtained: false,
  current_unreaded_count: 0,
  pause_all_preview_video: false,
  open_preview_modal: false,
  preview_attachment_list: [],
  preview_attachment_type: "image",
  chatbot_dialog_id: "",
  chatbot_id: null,
  retry_QB: false,
  connection_retry: 0,
};
const initialUploadState = {
  sent: 0,
  pending: 0,
};
const MessageSlice = createSlice({
  name: "message_reducer",
  initialState,
  reducers: {
    updateNetworkConnectionRetry: (state) => {
      state.connection_retry += 1;
    },
    resetConnectionRetry: (state) => {
      state.connection_retry = 0;
    },
    updateRetryQB: (state, action) => {
      state.retry_QB = action.payload;
      if (!action.payload) {
        state.connection_retry = 0;
      }
    },
    updateUserData: (state, action) => {
      state.user = action.payload;
    },
    updateMessageloader: (state, action) => {
      state.message_loader = action.payload;
    },
    updateDialogList: (
      state,
      action: PayloadAction<MessageDialogListInterface[]>
    ) => {
      let dialogUnread = 0;
      let dialogDirectUnread = 0;
      let dialogOtherUnread = 0;
      state.dialog_list = {};

      for (let i = 0; i < action.payload.length; i++) {
        let dialog = action.payload[i];
        if (
          dialog.data.topic_type &&
          chatbot_dailog_filter.includes(dialog.data.topic_type)
        ) {
          continue;
        }
        if (!state.chat_upload[dialog._id]) {
          state.chat_upload[dialog._id] = initialUploadState;
        }
        if (dialog.unread_messages_count !== 0) {
          dialogUnread += 1;
          if (dialog.data.permitted_user.includes(state.user_id))
            dialogDirectUnread += 1;
          else dialogOtherUnread += 1;
        }
        state.dialog_list[dialog._id] = dialog;
      }

      let new_cache_dailog = {
        [state.user_id]: state.dialog_list,
      };
      localStorage.setItem("cache_dailog", JSON.stringify(new_cache_dailog));
      state.unread_count.dialog_unread = dialogUnread;
      state.unread_count.dialog_direct_unread = dialogDirectUnread;
      state.unread_count.dialog_other_unread = dialogOtherUnread;
      state.message_loader = false;
    },

    updateReconnectMessage: (state, action) => {
      state.reconnecting_message = action.payload;
    },
    updateCreateNewChatPending: (state) => {
      state.new_message_modal_loader = true;
    },
    updateChatHistory: (
      state,
      action: PayloadAction<{
        id: string | number;
        chats: ChatInterface[];
        noMore?: boolean;
        chatbot?: boolean;
      }>
    ) => {
      const {
        id = "",
        chats,
        noMore = false,
        chatbot = false,
      } = action.payload;
      state.active_dialog.noMore = false;
      let history = state.chat_history;

      if (noMore && history[id]) {
        state.active_dialog.noMore = true;
      } else {
        let history = state.chat_history;
        let chatParsing: ChatInterface[] = [];
        chats.reverse().forEach((chat) => {
          let currentIteration: ChatInterface[] = [];
          currentIteration = [chat];

          chatParsing = [...chatParsing, ...currentIteration];
        });
        if (history[id]) {
          history[id] = [...chatParsing, ...history[id]];
        } else {
          history[id] = chatParsing;
        }

        if (!chatbot) {
          state.active_dialog_id = id;
          state.new_message_page = false;
        }

        state.chat_history = history;
      }
    },
    updateLoadMoreChat: (state, action: PayloadAction<boolean>) => {
      state.loading_more_chats = action.payload;
    },
    updateGoEnd: (state, action: PayloadAction<boolean>) => {
      state.go_end = action.payload;
    },
    setInitialState: (state) => {
      state = { ...initialState };
      return state;
    },
    updateActiveDialogDetails: (
      state,
      action: PayloadAction<MessageDialogListInterface>
    ) => {
      state.active_dialog = action.payload;
      state.active_dialog_id = action.payload._id;
      state.current_unreaded_count = action.payload.unread_messages_count;
    },
    updateCurrentUnreadinChat: (state, action) => {
      state.current_unreaded_count = action.payload;
    },
    resetActiveDailog: (state) => {
      state.active_dialog = { ...initialActiveChatState };
      state.active_dialog_id = "";
    },
    updateUserDetails: (state, action: PayloadAction<UserDetailsInterface>) => {
      state.global_admin_details = action.payload["globaladmin"];
      state.backend_details = action.payload["backend"];
      state.user_list = [...state.user_list, ...action.payload["userlist"]];
      state.user_id_n_name = {
        [state.user_id]: state.user_name,
        ...action.payload["useridNameList"],
      };
    },
    updateResponsiveChat: (state, action) => {
      state.view_responsive_chats = action.payload;
    },
    updateUploadSentCount: (state, action) => {
      const {
        id,
        upload_list = [],
        type = "",
        caption = "",
        msg_style = "",
        mys_type = "",
        mys_grade_name = "",
        mys_count = "",
      } = action.payload;

      state.chat_upload[id].sent += 1;
      if (state.chat_upload[id].sent === state.chat_upload[id].pending) {
        state.chat_upload[id].sent = 0;
        state.chat_upload[id].pending = 0;
        if (type === "image")
          QB_sendSyncGroupImg({
            id,
            upload_list,
            user: state.user,
            caption: caption,
            msg_style,
            mys_type,
            mys_count,
            mys_grade_name,
          });
      }
    },

    updateCreateNewChatFullFilled: (state, action) => {
      state.active_dialog = { ...state.active_dialog, ...action.payload };
      state.active_dialog_id = action.payload._id;
      state.view_responsive_chats = !state.view_responsive_chats;
      state.new_message_modal_loader = false;
      state.show_new_message_modal = false;
      state.new_message_creation_failed = false;
    },
    updateCreateNewChatRejected: (state) => {
      state.new_message_modal_loader = false;
      state.new_message_creation_failed = true;
    },
    resetChatCreationFailure: (state) => {
      state.new_message_creation_failed = false;
    },

    updateNewMessageModal: (state, action: PayloadAction<boolean>) => {
      state.show_new_message_modal = action.payload;
      state.new_message_creation_failed = false;
    },
    updateUploadPendingCount: (state, action) => {
      const { id, count } = action.payload;
      if (state.chat_upload[id]) state.chat_upload[id].pending = count;
      else {
        state.chat_upload[id] = { sent: 0, pending: count };
      }
    },
    updateReadMessage: (state, action) => {
      const { id, message_id } = action.payload;
      let temp = state.chat_history[id];
      for (let index = 0; index < temp.length; index++) {
        if (temp[index].read === 1 && temp[index]._id === message_id) {
          temp[index].read = 0;
          break;
        }
      }
      state.chat_history[id] = temp;
    },
    updateReadChatDialog: (state, action) => {
      let dailogUnread = 0;
      let dailogDirectUnread = 0;
      let dailogOtherUnread = 0;

      if (state.dialog_list) {
        state.dialog_list[action.payload].unread_messages_count = 0;
        for (let el of Object.values(state.dialog_list)) {
          if (el.unread_messages_count !== 0) {
            dailogUnread += 1;
            if (el.data.permitted_user.includes(state.user_id))
              dailogDirectUnread += 1;
            else dailogOtherUnread += 1;
          }
        }
      }

      state.unread_count.dialog_unread = dailogUnread;
      state.unread_count.dialog_direct_unread = dailogDirectUnread;
      state.unread_count.dialog_other_unread = dailogOtherUnread;
    },
    updateLastSeen: (state, action) => {
      state.last_sent_message_time = action.payload;
    },

    updateUserId: (state, action: PayloadAction<number>) => {
      state.user_id = action.payload;
      state.qb_initiated = true;
      let dailogUnread = 0;
      let dailogDirectUnread = 0;
      let dailogOtherUnread = 0;
      const cache_dailog = localStorage.getItem("cache_dailog");

      if (cache_dailog !== null) {
        state.message_loader = false;
      }
      const chat_list: null | {
        [user_id: number]: { [id: string]: MessageDialogListInterface };
      } = cache_dailog !== null ? JSON.parse(cache_dailog) : {};
      if (chat_list && chat_list[state.user_id]) {
        for (let dialog of Object.values(chat_list[state.user_id])) {
          if (dialog.unread_messages_count !== 0) {
            dailogUnread += 1;
            if (dialog.data.permitted_user.includes(state.user_id))
              dailogDirectUnread += 1;
            else dailogOtherUnread += 1;
          }
        }

        state.dialog_list = chat_list[state.user_id];
        state.unread_count.dialog_unread = dailogUnread;
        state.unread_count.dialog_direct_unread = dailogDirectUnread;
        state.unread_count.dialog_other_unread = dailogOtherUnread;
      }
    },
    updateDialog: (state, action) => {
      let dialogUnread = 0;
      let dialogDirectUnread = 0;
      let dialogOtherUnread = 0;
      const dialog = action.payload;
      if (!state.chat_upload?.[dialog._id]) {
        state.chat_upload[dialog._id] = initialUploadState;
      }

      state.dialog_list = { ...state.dialog_list, [dialog._id]: dialog };

      if (state.active_dialog._id === dialog._id) {
        state.active_dialog = { ...state.active_dialog, ...dialog };
        state.active_dialog_id = dialog._id;
      }
      for (let d of Object.values(state.dialog_list)) {
        if (d.unread_messages_count !== 0) {
          dialogUnread += 1;
          if (d.data.permitted_user.includes(state.user_id))
            dialogDirectUnread += 1;
          else dialogOtherUnread += 1;
        }
      }
      state.unread_count.dialog_unread = dialogUnread;
      state.unread_count.dialog_direct_unread = dialogDirectUnread;
      state.unread_count.dialog_other_unread = dialogOtherUnread;
    },
    updateActiveDailogDetails: (
      state,
      action: PayloadAction<MessageDialogListInterface>
    ) => {
      state.active_dialog = { ...state.active_dialog, ...action.payload };
      state.active_dialog_id = action.payload._id;
    },
    updateChat: (
      state,
      action: PayloadAction<{
        id: string | number;
        message: ChatInterface;
        chatbot_const_text: any;
      }>
    ) => {
      const { id = "", message, chatbot_const_text } = action.payload;

      const helpfulData: ChatInterface = chatbot_helpfulMessage(
        state,
        chatbot_const_text
      );
      const relatedData: ChatInterface = chatbot_relatedMessage(
        state,
        chatbot_const_text
      );
      //eslint-disable-next-line
      const exploreData: ChatInterface = chatbot_exploreMessage(
        state,
        chatbot_const_text
      );
      let updateHelpful = true;
      if (
        message.message &&
        message.message.toLowerCase().trim() ===
          chatbot_const_text.unknown_note.toLowerCase().trim()
      ) {
        updateHelpful = false;
        message["questions_type"] = "categories";
        message.sendasclient = 1;
      }
      if (state.chat_history[id]) {
        let updatedChat = [message];
        if (
          message.is_bot_message &&
          message.is_bot_message === "1" &&
          !state.hide_helpful_msg &&
          updateHelpful
        ) {
          updatedChat = [relatedData, ...updatedChat, helpfulData];
        }
        if (
          message.is_bot_message &&
          message.is_bot_message === "1" &&
          state.hide_helpful_msg &&
          updateHelpful
        ) {
          message.questions_type = "categories";
          updatedChat = [message];
        }
        if (message.questions_type === "answers") {
          updatedChat = [...updatedChat, helpfulData];
        }
        state.chat_history[id] = [...state.chat_history[id], ...updatedChat];
      }

      if (
        state.active_dialog_id === message.dialog_id &&
        state.current_unreaded_count !== 0
      ) {
        state.current_unreaded_count = 0;
      }
      if (message.is_bot_message && message.is_bot_message === "1")
        state.hide_helpful_msg = false;
    },
    toggleHideHelpful: (state, action) => {
      state.hide_helpful_msg = action.payload;
    },
    updateActiveAttachmentVideo: (state, action) => {
      state.attachment_active_video_index = action.payload;
    },

    setSessionToEmpty: (state) => {
      state.session_id = "";
    },
    pauseAllPreviewVideo: (state, action) => {
      state.pause_all_preview_video = action.payload;
    },
    openPreviewModal: (
      state,
      action: PayloadAction<{ type: string; list: string[] }>
    ) => {
      const { type = "image", list = [] } = action.payload;
      state.open_preview_modal = true;
      state.preview_attachment_list = list;
      state.preview_attachment_type = type;
    },
    closePreviewModal: (state) => {
      state.open_preview_modal = false;
      state.preview_attachment_list = [];
      state.preview_attachment_type = "image";
    },
    resetMessageReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GET("profileDetails", "").fulfilled, (state, action) => {
      state.chatbot_dialog_id =
        action.payload.data?.web_chatbot_dialog_id || "";
      state.chatbot_id =
        action.payload.data?.quickblox_config?.chatbot_id || null;
      state.chat_history = { [state.chatbot_dialog_id]: [] };
    });
    builder.addCase(GET("usersession", "").fulfilled, (state, action) => {
      state.session_id = action.payload.data.qb_session_token;
    });

    builder.addCase(GET("userlist", "").fulfilled, (state, action) => {
      const restrictedUsertags = ["globaladmin", "backend"];
      const admindetails: UserDetailsInterface = {} as UserDetailsInterface;
      const userList = action.payload.data.reduce(
        (prev: UserListInterface[], el: UserListInterface) => {
          const user_tag = el.user_tags.toLowerCase();
          if (restrictedUsertags.includes(el.user_tags.toLowerCase())) {
            if (user_tag === "globaladmin") admindetails["globaladmin"] = el;
            if (user_tag === "backend") admindetails["backend"] = el;
            return prev;
          }
          return [...prev, el];
        },
        []
      );

      const userObject = action.payload.data.reduce(
        (prev: { [id: number]: string }, el: UserListInterface) => {
          prev[el.id] = el.full_name;
          return prev;
        },
        {}
      );

      admindetails["userlist"] = [...userList];
      admindetails["useridNameList"] = { ...userObject };

      state.global_admin_details = admindetails["globaladmin"];
      state.backend_details = admindetails["backend"];
      state.user_list = [...admindetails["userlist"]];
      state.user_id_n_name = {
        [state.user_id]: state.user_name,
        ...admindetails["useridNameList"],
      };

      localStorage.setItem(
        "global_admin_details",
        JSON.stringify(state.global_admin_details)
      );
      localStorage.setItem(
        "backend_details",
        JSON.stringify(state.backend_details)
      );
      localStorage.setItem("user_list", JSON.stringify(state.user_list));
      localStorage.setItem(
        "user_id_n_name",
        JSON.stringify(state.user_id_n_name)
      );
    });
  },
});

export default MessageSlice.reducer;
export const {
  updateMessageloader,
  updateActiveDialogDetails,
  updateChatHistory,
  updateUserDetails,
  updateResponsiveChat,
  updateCreateNewChatPending,
  updateCreateNewChatFullFilled,
  updateCreateNewChatRejected,
  updateNewMessageModal,
  updateReadMessage,
  updateLastSeen,
  updateUserId,
  updateChat,
  updateLoadMoreChat,
  updateGoEnd,
  resetActiveDailog,
  resetChatCreationFailure,
  updateActiveAttachmentVideo,
  updateReconnectMessage,
  updateUploadSentCount,
  setInitialState,
  updateUploadPendingCount,
  updateReadChatDialog,
  setSessionToEmpty,
  resetMessageReducer,
  pauseAllPreviewVideo,
  openPreviewModal,
  closePreviewModal,
  updateUserData,
  toggleHideHelpful,
  updateDialogList,
  updateDialog,
  updateNetworkConnectionRetry,
  resetConnectionRetry,
  updateRetryQB,
} = MessageSlice.actions;
