import { ISummaryDetails } from "../../interfaces/ICommonData";
import { Price, TextBold } from "../OfferOrderSummary/OfferOrderSummaryStyled";
export const Details = ({
  label = "",
  value = "",
  changed_value = null,
}: ISummaryDetails) => {
  return (
    <div className="details">
      <span className="label">{label}:</span>
      <span className="value">
        <span>{value}</span>
        {changed_value && (
          <span className="price_change_text">
            <span> Changed from: </span>
            <span> {changed_value}</span>
          </span>
        )}
      </span>
    </div>
  );
};

export const DetailsPricing = ({
  label = "",
  value = "",
  changed_value = "",
  type = "",
}: ISummaryDetails) => {
  return (
    <div className="details">
      <span className="label">{label}:</span>
      <Price>
        <span>{value}</span>
        {type !== "order" && (
          <span className="price_change_text">
            Changed from: {changed_value}
          </span>
        )}
      </Price>
    </div>
  );
};

export const BoldDetails = ({
  label = "",
  value = "",
  changed_value = null,
}: ISummaryDetails) => {
  return (
    <TextBold className="details text_bold">
      <span>{label}:</span>
      <span className="value">
        <span>{value}</span>
        {changed_value && (
          <span className="price_change_text">
            <span> Changed from: </span>
            <span> {changed_value}</span>
          </span>
        )}
      </span>
    </TextBold>
  );
};
